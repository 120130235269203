import React, { useEffect } from "react";
import "./BlanchPeanuts.css"
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import images from "../../constants/images";

const BlanchPeanuts = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | Blanched Peanuts</title>
        <meta
          name="description"
          content="Offering premium Blanched Peanuts, blanched peanuts."
        />
      </Helmet>
      <div style={{ paddingBottom: "0rem" }}>
        <Navbar />
      </div>
      <meta
        name="keywords"
        content="Offering premium Blanched Peanuts, blanched peanuts."
      />
      <div className="pro_head_main">Blanched Peanuts</div>

      <div className="RawPeanut_content_main BlanchPeanut_main">
        <div className="BlanchPeanut_img_main">
          <img
            src={images.BlanchPeanuts}
            alt="BlanchPeanuts"
            className="Peanuts_image_slider_inner"
          />
          <h2 className="Peanut_slider_name" style={{ paddingBottom: "0px" }}>
            Blanched Peanuts
          </h2>
        </div>
        <table className="BlanchPeanut_table_main">
          <tr>
            <th>HS Code</th>
            <td>12024210 </td>
          </tr>
          <tr>
            <th>Variety Available</th>
            <td>BOLD , JAVA , TJ </td>
          </tr>
          <tr>
            <th>Count / Once Available</th>
            <td>35-40, 38-42, 40-50, 50-60, 60-70, 80-90</td>
          </tr>
          <tr>
            <th>Packaging Available</th>
            <td>20/25/50 kg PP/ Jute Bags/ Vaccum or Customised </td>
          </tr>
          <tr>
            <th>Odor</th>
            <td>Creamy &amp; Nutty </td>
          </tr>
          <tr>
            <th>Storage Condition</th>
            <td>Store at 10-20° C </td>
          </tr>
          <tr>
            <th>Shelf life</th>
            <td>1 Year from Production Date </td>
          </tr>
          <tr>
            <th>Microbiological Parameters</th>
            <td>As per buyers Requirements </td>
          </tr>
          <tr>
            <th>Loading</th>
            <td>19 MTs (20 ft), 26 MTs (40 ft)</td>
          </tr>
        </table>
      </div>
      <div style={{ paddingBottom: "2rem" }}>
        <p className="para BlanchPeanut_para_main">
          Blanched peanuts are high-quality peanuts with their outer skin
          removed through a specialized blanching process. This creates smooth,
          creamy kernels that are ideal for snacking or use in various culinary
          applications. Packed with protein, healthy fats, and essential
          nutrients, blanched peanuts are a versatile and nutritious choice.
        </p>
        <p className="para BlanchPeanut_para_main" style={{ marginTop: "0px" }}>
          We export wide range of blanched peanuts from India. Blanched peanuts
          are versatile and widely used in salted snacks, confectionery, bakery
          products, sweets, chocolates, ice creams, and more. Tatva Overseas
          offers premium-quality whole blanched peanuts, blanched split peanuts,
          and graded varieties like 35-40, 38-42, 40-50, 50-60, 60-70, 80-90
          blanched peanuts. Known for their long shelf life and rich aromatic
          fragrance, these peanuts are ideal for cooking and baking recipes that
          demand a light color and smooth texture. Blanched peanuts provide the
          same nutritional benefits as regular peanuts, including protein,
          healthy fats, vitamins, and minerals, making them a wholesome addition
          to a balanced diet.{" "}
        </p>
      </div>
    </>
  );
};

export default BlanchPeanuts;
