import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./RawPeanut.css";
// import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import images from "../../constants/images";

const RawPeanut = () => {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 2,
    slidesToScroll: 1,
    spaceBetween:30,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | Oil Seeds</title>
        <meta
          name="description"
          content="Offering premium oilseeds and nuts worldwide, including raw peanuts, peanut in shell, blanched peanuts, natural sesame seeds, black sesame seeds, hulled sesame seeds, and roasted sesame seeds. Purity and quality guaranteed."
        />{" "}
      </Helmet>
      <div style={{ paddingBottom: "0rem" }}>
        <Navbar />
      </div>
      <meta
        name="keywords"
        content="Indian Peanuts, oil seeds, Oil seeds Exporters, Best Oil seeds Exporters"
      />
      <div className="pro_head_main">Groundnut Kernels</div>

      <div className="RawPeanut_content_main">
        <div>
          <Slider {...settings}>
            <div>
              <img
                src={images.BoldPeanuts}
                alt="BoldPeanut"
                className="Peanuts_image_slider_inner"
              />
              {/* <h2 className="Peanut_slider_name">BOLD</h2> */}
            </div>
            <div>
              <img
                src={images.BoldPeanut}
                alt="BoldPeanut"
                className="Peanuts_image_slider_inner"
              />
              {/* <h2 className="Peanut_slider_name">BOLD</h2> */}
            </div>
            <div>
              <img
                src={images.JavaPeanut}
                alt="JavaPeanut"
                className="Peanuts_image_slider_inner"
              />
              {/* <h2 className="Peanut_slider_name">Java</h2> */}
            </div>
            <div>
              <img
                src={images.TJPeanut}
                alt="TJPeanut"
                className="Peanuts_image_slider_inner"
              />
              {/* <h2 className="Peanut_slider_name">TJ</h2> */}
            </div>
          </Slider>
        </div>
        <table>
          <tr>
            <th>HS Code</th>
            {/* <td>102024210 </td> */}
            <td>12024210 </td>
          </tr>
          <tr>
            <th>Variety Available</th>
            <td>BOLD , JAVA , TJ </td>
          </tr>
          <tr>
            <th>Count / Once Available</th>
            <td>35/40 , 38/42 , 40/50 , 50/60 , 60/70 , 80/90 </td>
          </tr>
          <tr>
            <th>Packaging Available</th>
            <td>20/25/50 kg PP/ Jute Bags/ Vaccum or Customised </td>
          </tr>
          <tr>
            <th>Odor</th>
            <td>Creamy &amp; Nutty </td>
          </tr>
          <tr>
            <th>Storage Condition</th>
            <td>Store at 10-20° C </td>
          </tr>
          <tr>
            <th>Shelf life</th>
            <td>1 Year from Production Date </td>
          </tr>
          <tr>
            <th>Microbiological Parameters</th>
            <td>As per buyers Requirements </td>
          </tr>
          <tr>
            <th>Loading</th>
            <td>19 MTs (20 ft), 26 MTs (40 ft)</td>
          </tr>
        </table>
        <div style={{ paddingBottom: "2rem" }}>
          <p className="para">
            Groundnuts are nutritious, offering protein, fiber, healthy fats,
            and vitamins. They may benefit heart health, aid weight management,
            and regulate blood sugar. The three main peanut cultivars grown in
            India are TJ, Java (Spanish), and Bold (Virginia, Runner). In India,
            the Agricultural and Processed Food Products Export Development
            Authority, or APEDA, is working on a quality push for the peanut
            business that has begun to produce solid practices in aflatoxin
            management and food safety. We instruct farmers on sustainable
            farming methods so they can produce the highest-quality goods.
          </p>
          <p className="para" style={{ marginTop: "0px" }}>
            Groundnuts can be used in a variety of ways, such as groundnut oil,
            roasted, blanched, split, salted, raw, or as a paste that is
            commonly referred to as peanut butter. The crop's most valuable
            byproduct, groundnut oil, is utilized in both household and
            commercial settings. About 65% of the world's groundnut production
            is used in the extraction of edible oil. Groundnut cake is used for
            a number of purposes. We are among the top producers, distributors,
            and exporters of a variety of groundnut kernels in various
            varieties. To guarantee a dedicated shelf life, exceptional quality,
            and the absence of any additional allergies, ground nuts are
            prepared under hygienic circumstances.
          </p>
        </div>
      </div>
    </>
  );
};

export default RawPeanut;
