/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import "./Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);


  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch("https://tatvaoverseas.in/form_api.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: formData.name,
          contact: formData.phone, // Use "contact" to match PHP API
          email: formData.email,
          message: formData.message,
        }),
      });

      const result = await response.json();

      if (result.success) {
        toast.success("✅ Your message has been sent successfully!");
        setFormData({ name: "", phone: "", email: "", message: "" }); // Clear form
      } else {
        toast.error("❌ Error: " + result.message);
      }
    } catch (error) {
      toast.error("❌ Submission failed! Please try again.");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | Contact</title>
      </Helmet>
      <Navbar />
      <div className="ContactUsHome_main">
        <div className="pro_head_main">CONTACT US</div>

        <div className="heading_sec_contact_main">
          <div>
            <h2 style={{ textAlign: "center" }}>
              Reach out to us – we're here to help your business thrive!
            </h2>
          </div>
        </div>
        <div className="form_sec_main_home">
          <div className="contactUs_Keep_in_touch_main">
            <h1>Keep in touch</h1>
            <div>
              <p>
                We run a business grounded in trust, which we build through
                clear communication and dependable support. From your first
                interaction with us and through each anniversary, we're here for
                you every step of the way.
              </p>
            </div>
            <div>
              <p>
                We look forward to discussing your business needs and answering
                any questions you may have. Just enter your details, and we'll
                get back to you shortly. Our team undergoes regular specialized
                training to ensure you receive the best information possible.
                Whether it’s a simple question or a complex compliance inquiry,
                we’re here to help.
              </p>
            </div>
          </div>

          <form className="form_sec_main_home_inner" onSubmit={handleSubmit}>
            <div className="form_sec_main_home_inner_part">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="input_field"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Contact no."
                className="input_field"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_sec_main_home_inner_part">
              <input
                type="email"
                name="email"
                placeholder="Email id."
                className="input_field"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <textarea
                name="message"
                rows="9"
                cols="50"
                placeholder="Write your message here"
                className="input_field_message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <button
                className="button_dark"
                type="submit"
                style={{ padding: "0.7rem", width: "100%" }}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
};

export default Contact;
