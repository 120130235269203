import { React, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import images from "../../constants/images";
import "./HomePriceInquiry.css";

const HomePriceInquiry = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    message: "",
    productName: "",
    productQty: "",
    // paymentTerms: "",
    port: "",
    website: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({ ...prev, contact: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    // Send form data to PHP script
    const response = await fetch("https://tatvaoverseas.in/form_api.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },

      body: JSON.stringify({
        name: formData.name,
        contact: formData.contact,
        email: formData.email,
        message: formData.message,
        productName: formData.productName, // Use "contact" to match PHP API
        productQty: formData.productQty, // Use "contact" to match PHP API
        port: formData.port, // Use "contact" to match PHP API
        website: formData.website, // Use "contact" to match PHP API
      }),
    });

    const data = await response.json();

    // Handle the response
    if (data.success) {
      toast.success("Form submitted successfully!");
      setFormData({
        // Reset form after submission
        name: "",
        contact: "",
        email: "",
        message: "",
        productName: "",
        productQty: "",
        port: "",
        website: "",
      }); // Log formData before sending it to ensure all fields are populated
    } else {
      toast.error("Failed to submit the form.");
    }
  };

  return (
    <div
      className=""
      style={{
        backgroundPosition: "center",
        backgroundImage: `url(${images.bgImage})`,
      }}>
      <div className="form_main_top">
        <div className="form_main">
          <div className="values_head_main">
            <div className="heading">
              <h1>Get a Price Quote</h1>
            </div>
            <div className="underline"></div>
          </div>
          <form onSubmit={handleSubmit} className="quote_form_main">
            <div>
              <label className="field_main">
                Name *
                <input
                  placeholder="E.g. Rafael Nadal"
                  className="input_field input_transparent"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Product name / HSN code *
                <input
                  type="text"
                  name="productName"
                  value={formData.productName}
                  onChange={handleChange}
                  className="input_field input_transparent"
                  placeholder="E.g. Fresh Mangoes / 08045020"
                  required
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Product Quantity (Metric Ton) *
                <input
                  type="number"
                  name="productQty"
                  value={formData.productQty}
                  onChange={handleChange}
                  className="input_field input_transparent"
                  placeholder="E.g. 50 MT"
                  required
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Email *
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input_field input_transparent"
                  placeholder="E.g. name@gmail.com"
                  required
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Arival Port *
                <input
                  type="text"
                  name="port"
                  value={formData.port}
                  onChange={handleChange}
                  className="input_field input_transparent"
                  placeholder="E.g. Port of Jebel Ali"
                  required
                />
              </label>
            </div>
            <div className="field_main">
              <label>Contact *</label>
              {/* <PhoneInput
                  country={"us"}
                  placeholder="E.g +91 97250 40440"
                  style={{
                    padding: "0px",
                    paddingLeft: "0.6rem",
                    flexDirection: "row",
                  }}
                  name="contact"
                  className="input_field"
                  value={formData.contact}
                  onChange={handlePhoneChange}
                /> */}
              <PhoneInput
                country={"us"}
                placeholder="E.g +91 97250 40440"
                style={{
                  padding: "0px",
                  paddingLeft: "0.6rem",
                  flexDirection: "row",
                }}
                name="contact"
                className="input_field"
                value={formData.contact}
                onChange={handlePhoneChange}
                required
              />
            </div>
            {/* <div className="field_main">
                <legend>Delivery Terms *</legend>
                <div className="input_field">
                  <label className="checkbox_inner">
                    <input
                      type="checkbox"
                      name="delTerms"
                      value="FOB"
                      checked={formData.delTerms.includes("FOB")}
                      onChange={handleCheckboxChange}
                    />
                    FOB
                  </label>
                  <label className="checkbox_inner">
                    <input
                      type="checkbox"
                      name="delTerms"
                      value="CIF"
                      checked={formData.delTerms.includes("CIF")}
                      onChange={handleCheckboxChange}
                    />
                    CIF
                  </label>
                </div>
              </div> */}
            <div>
              <label className="field_main">
                Enter your website URL{" "}
                <input
                  name="website"
                  className="input_field input_transparent"
                  value={formData.website}
                  onChange={handleChange}
                  placeholder="E.g. tatvaOverseas.in"
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Description
                <textarea
                  name="message"
                  cols="30"
                  rows="6"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Detailed Description of the Product and Your Requirements"
                  className="input_field_message input_transparent"
                  style={{ width: "100%" }}
                  required></textarea>
              </label>
            </div>
            <div className="quote_btn_main">
              <button type="submit" className="quote_button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>{" "}
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default HomePriceInquiry;
